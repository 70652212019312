import { useSelector } from "react-redux";
import RecipMainRecipes from "./recipMainRecipes";
import RecipMainTags from "./recipMainTags";
import RecipMainRecipe from "./recipMainRecipe";

const RecipMain = () => {
    const location = useSelector((state) => state.main.location);

    switch(location) {
        case "recipes":
            return (
                <RecipMainRecipes />
            );
        break;
        case "tags":
			return (
				<RecipMainTags />
			);
        break;
        case "recipe":
			return (
				<RecipMainRecipe />
			);
        break;
        default:
            return(
                <div id="Recip_Center_Main" className="Recip_Center_Main">
                    <div className="Recip_Recipe_Title">Error Page</div>
                    <div>An unknown error has occured.</div>
                </div>
            );
        break;
    }
}

export default RecipMain;