import { useSelector } from "react-redux";

const RecipRecipeInstructions = ({ InstructionID, Parent }) => {
    const RecipeID = useSelector((state) => state.main.recipeid);
    const Recipe = useSelector((state) => state.main.currentrecipe);

	if (Recipe.Instructions[InstructionID].Parent.$oid.toString() === Parent) {
		return (
			<div key={"recipe_instructions_"+RecipeID+"."+InstructionID} className="Recip_Recipe_Text">
				<div className="Recip_Recipe_Instruction">{Recipe.Instructions[InstructionID].Text}</div>
			</div>
		);
	}
	else {
		return (null);
	}
}

export default RecipRecipeInstructions;