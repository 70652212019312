import { useSelector } from "react-redux";

const RecipRecipeTags = ({ TagID, Tags }) => {
    const Recipe = useSelector((state) => state.main.currentrecipe);
    if (Tags.length > 0) {
        let Tag = Tags.filter(t => t.TagID === Recipe.Tags[TagID].$oid.toString());
        return (
            <span className="Recip_Recipe_Tag">#{Tag[0].Text}</span>
        );
    }
    else {
        return (null);
    }
}

export default RecipRecipeTags;