const RecipQuickSearch = () => {
    return (
		<div id="Recip_Qsearch" className="Recip_Qsearch">
			<div className="Recip_Qsearch_Title">
				Quick Search
			</div>
			<div className="Recip_Qsearch_Search">
				<input type="text" className="Recip_Qsearch_Search_Text" />
                <input type="button" value="Go" className="Recip_Qsearch_Search_Go" />
			</div>
			<div className="Recip_Qsearch_Title">
				Results
			</div>
		</div>
	);
}

export default RecipQuickSearch;