import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import getRecipeList from "../script/getRecipes";
import { move, importrecipes, setrecipe } from "../store/mainSlice";

const RecipMainRecipes = () => {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);
    useEffect(() => {
        getRecipeList().then((data)=>{
            console.log(data);
            stableDispatch(importrecipes(data));
        });
    }, [stableDispatch]);
    const Recipes = useSelector((state) => state.main.recipes);

return (
    <div id="Recip_Center_Main" className="Recip_Center_Main">
        <div className="Recip_Recipe_Title">Recipe List</div>
        <div>
            <ul className="Recip_List">
            {Recipes.map((Recipe, index) => {
                return(
                    <li id={"recipe_"+Recipe.RecipeID} key={"recipe_"+Recipe.RecipeID} onClick={() => {
                        dispatch(setrecipe(Recipe.RecipeID));
                        dispatch(move("recipe"))
                    }}>{Recipe.Title}</li>
                );
            })}
            </ul>
        </div>
    </div>
);
}

export default RecipMainRecipes;