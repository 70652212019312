import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
    name: 'main',
    initialState: {
        location: 'recipes',
        recipes: [],
        tags: [],
        recipeid: '',
        currentrecipe: {}
    },
    reducers: {
        move: (state, action) => {
            state.location = action.payload;
            console.log("Moved to...",action.payload);
        },
        importrecipes: (state, action) => {
            state.recipes = action.payload;
        },
        importtags: (state, action) => {
            state.tags = action.payload;
        },
        setrecipe: (state, action) => {
            state.recipeid = action.payload;
        },
        setcurrentrecipe: (state, action) => {
            state.currentrecipe = action.payload;
        }
    },
});

export const { move, importrecipes, importtags, setrecipe, setcurrentrecipe } = mainSlice.actions;

export default mainSlice.reducer;