import { useSelector } from "react-redux";

const RecipRecipeNotes = ({ NoteID }) => {
    const Recipe = useSelector((state) => state.main.currentrecipe);

	return (
		<div className="Recip_Recipe_Text">
			<div className="Recip_Recipe_Instruction">{Recipe.Notes[NoteID].Text}</div>
		</div>
	);
}

export default RecipRecipeNotes;