import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import getRecipe from "../script/getRecipe";
import { setcurrentrecipe,importtags } from "../store/mainSlice";
import { VarExists } from "../script/common";
import RecipRecipeIngredient from "./recipe/recipRecipeIngredient";
import RecipRecipeInstructions from "./recipe/recipRecipeInstructions";
import RecipRecipeNotes from "./recipe/recipRecipeNotes";
import getTagsList from "../script/getTags";
import RecipRecipeTags from "./recipe/recipRecipeTags";

const RecipMainRecipe = () => {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);
    const RecipeID = useSelector((state) => state.main.recipeid);
    useEffect(() => {
        getRecipe(RecipeID).then((data)=>{
            stableDispatch(setcurrentrecipe(data));
        });
        getTagsList().then((data)=>{
            stableDispatch(importtags(data));
        });
    }, [stableDispatch]);
    const Recipe = useSelector((state) => state.main.currentrecipe);
    const Tags = useSelector((state) => state.main.tags);

    if (VarExists(Recipe.RecipeID)) {
        return (
            <div id="Recip_Center_Main" className="Recip_Center_Main">
                <div className="Recip_Recipe_Title">{Recipe.Title}</div>
                <div className="Recip_Recipe_Image">An image of the finished item</div>
                <div className="Recip_Recipe_Heading">INGREDIENTS</div>
                <div className="Recip_Recipe_Subsection">
                    {
                        Recipe.Components.map((Component, index) => {
                            if (Component.HasIngredients === 1) {
                                return (
                                    <div key={"ingredient_list_"+RecipeID+"."+index}>
                                        {Component.Description !== "(default)"?<div className="Recip_Recipe_Sub_Heading">{Component.Description}</div>:""}
                                        <ul className="Recip_Recipe_List">
                                            {Recipe.Ingredients.map((Ingr, index) => {
                                                return(
                                                    <RecipRecipeIngredient key={"ingr_list_"+RecipeID+"."+Component.ComponentID+"."+index} IngredientID={index} Parent={Component.ComponentID} />
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </div>
                <div className="Recip_Recipe_Heading">INSTRUCTIONS</div>
                <div className="Recip_Recipe_Subsection">
						{Recipe.Components.map((Component, index) => {
							if (Component.HasInstructions === 1) {
								return (
                                    <div key={"instruction_list_"+RecipeID+"."+index}>
									    {Component.Description !== "(default)"?<div className="Recip_Recipe_Sub_Heading">{Component.Description}</div>:""}
										{Recipe.Instructions.map((Instr, index) => {
                                            return (
                                                <RecipRecipeInstructions key={"instr_list_"+RecipeID+"."+Component.ComponentID+"."+index} InstructionID={index} Parent={Component.ComponentID} />
										    );
                                        })}
								</div>
								);
							}
							else {
								return null;
							}
						})}
				</div>
                <br />
                <div className="Recip_Recipe_Heading">NOTES</div>
                <div className="Recip_Recipe_Subsection">
                    {Recipe.Notes.length < 1?<div className="Recip_Recipe_Text"><em>&nbsp;No notes found</em></div>:
                    Recipe.Notes.map((Note, index) => {
                        return (
                            <RecipRecipeNotes key={"note_list_"+RecipeID+"."+index} NoteID={index} />
                        );
                    })}
                </div>
                <div className="Recip_Recipe_Heading">TAGS</div>
                <div className="Recip_Recipe_Tags">
                    {Recipe.Tags.map((Tag, index) => {
                        return (
                            <RecipRecipeTags key={"tag_list_"+RecipeID+"."+index} TagID={index} Tags={Tags} />
                        );
                    })}
                </div>
                <div className="Recip_Recipe_Footer">
                    Uploaded by: <span className="Recip_Recipe_Author">{Recipe.Author}</span>
                </div>
            </div>
        );
    }
    else {
        return(
            <div id="Recip_Center_Main" className="Recip_Center_Main">
                <div className="Recip_Recipe_Title">Recipe loading...</div>
            </div>
        );
    }
    }
    
    export default RecipMainRecipe;