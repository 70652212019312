import { useSelector, useDispatch } from 'react-redux';
import { move } from '../store/mainSlice';

const RecipMenu = () => {
	const dispatch = useDispatch();
	const location = useSelector((state) => state.main.location);

    return (
		<div id="Recip_Menu" className="Recip_Menu">
			<ul>
				<li onClick={() => dispatch(move("recipes"))}>Recipes</li>
				<li onClick={() => dispatch(move("tags"))}>Tags</li>
				<li onClick={() => dispatch(move("authors"))}>Authors</li>
				<li onClick={() => dispatch(move("addrecipe"))}>Add Recipe</li>
				<li onClick={() => console.log("You are at...",location)}>Where Am I?</li>
			</ul>
			<div id="Recip_History" className="Recip_History">History</div>
			<ul>
                <li>Recipe 1</li>
                <li>Recipe 2</li>
                <li>Recipe 3</li>
                <li>Recipe 4</li>
			</ul>
		</div>
	);
}

export default RecipMenu;