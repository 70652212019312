const getRecipe = async (recipeid) => {
    let request = {
      method: 'recipe',
      id: recipeid
    };
    let url = "https://recip.painter.me.uk/api/data.php";
    let response = await fetch(url, { 
    method: "POST",
    headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
    body: JSON.stringify(request)
    });

    if (response.ok) {
      var recipeReturn = await response.json();
    }
    else {
      alert("HTTP-Error: " + response.status);
    }
    
    return recipeReturn['recipe'];
}

export default getRecipe;