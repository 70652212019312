import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import getTagsList from "../script/getTags";
import { importtags } from "../store/mainSlice";

const RecipMainTags = () => {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);
    useEffect(() => {
        getTagsList().then((data)=>{
            console.log(data);
            stableDispatch(importtags(data));
        });
    }, [stableDispatch]);
    const Tags = useSelector((state) => state.main.tags);

    return (
        <div id="Recip_Center_Main" className="Recip_Center_Main">
            <div className="Recip_Recipe_Title">Tags List</div>
            <div>
                <ul className="Recip_List">
                    {Tags.map((Tag, index) => {
                        return(
                            <li id={"tag_"+Tag.TagID} key={"tag_"+Tag.TagID}>{Tag.Text}</li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
    }
    
    export default RecipMainTags;