import { useSelector } from "react-redux";

const RecipRecipeIngredient = ({ IngredientID, Parent }) => {
    const RecipeID = useSelector((state) => state.main.recipeid);
    const Recipe = useSelector((state) => state.main.currentrecipe);

	if (Recipe.Ingredients[IngredientID].Parent.$oid.toString() === Parent) {
		return (
			<li className="Recip_Recipe_Ingredient">
				{Recipe.Ingredients[IngredientID].Quantity > 0 ? Recipe.Ingredients[IngredientID].Quantity+" ":""}
				{Recipe.Ingredients[IngredientID].Measure !== "" ? Recipe.Ingredients[IngredientID].Measure+" ":""}
				{Recipe.Ingredients[IngredientID].Description+" "}
				{Recipe.Ingredients[IngredientID].Alt.length > 0 ? (
					<em>{Recipe.Ingredients[IngredientID].Alt.map((alts, altindex) => {
						return (
							<span key={"alt_list_"+RecipeID+"."+IngredientID+"."+altindex}>( alt: {alts.Quantity > 0 ? alts.Quantity+" ":""} {alts.Measure !== "" ? alts.Measure+" ":""} {alts.Description+"; "})</span>
						);
					})}</em>
				) : ""}
			</li>
		);
	}
	else {
		return (null);
	}
}

export default RecipRecipeIngredient;