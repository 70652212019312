import React from 'react';
import './App.css';
import RecipFooter from './app/main/recipFooter';
import RecipMain from './app/main/recipMain';
import RecipMenu from './app/main/recipMenu';
import RecipQuickSearch from './app/main/recipQuickSearch';

function App() {
  return (
    <div id="Recip_Main" className="Recip_Main">
      <div id="Recip_Title" className="Recip_Title">ReciP</div>
      <RecipMenu />
      <RecipMain />
      <RecipQuickSearch />
      <RecipFooter />
		</div>
  );
}

export default App;
