const RecipFooter = () => {
    return (
		<div id="Recip_Footer" className="Recip_Footer">
			<br />
			<span id="Recip_Logout" className="Recip_Logout">Log out</span>
			<br />
			<span id="return"></span>
			<br />
		</div>
	);
}

export default RecipFooter;